import request from '@/utils/request1';

// 智能化管理查询
export function intelligenceList(data) {
    return request({ url: '/intelligence/QIntelligenceApi', method: 'post', data })
}

// 智能化管理新增
export function intelligenceAdd(data) {
    return request({ url: '/intelligence/AIntelligenceApi', method: 'post', data })
}

// 智能化管理编辑
export function intelligenceEdit(data) {
    return request({ url: '/intelligence/UIntelligenceApi', method: 'post', data })
}

// 智能化管理删除
export function intelligenceDel(data) {
    return request({ url: '/intelligence/DIntelligenceApi', method: 'post', data })
}

// 建立过程机制查询
export function establishList(data) {
    return request({ url: '/establish_mechanism/QEstablishMechanismApi', method: 'post', data })
}

// 建立过程机制新增
export function establishAdd(data) {
    return request({ url: '/establish_mechanism/AEstablishMechanismApi', method: 'post', data })
}

// 建立过程机制编辑
export function establishEdit(data) {
    return request({ url: '/establish_mechanism/UEstablishMechanismApi', method: 'post', data })
}

// 建立过程机制删除
export function establishDel(data) {
    return request({ url: '/establish_mechanism/DEstablishMechanismApi', method: 'post', data })
}

// 检查及审核周期查询
export function inspectionList(data) {
    return request({ url: '/inspection_cycle/QInspectionCycleApi', method: 'post', data })
}

// 检查及审核周期新增
export function inspectionAdd(data) {
    return request({ url: '/inspection_cycle/AInspectionCycleApi', method: 'post', data })
}

// 检查及审核周期编辑
export function inspectionEdit(data) {
    return request({ url: '/inspection_cycle/UInspectionCycleApi', method: 'post', data })
}

// 检查及审核周期删除
export function inspectionDel(data) {
    return request({ url: '/inspection_cycle/DInspectionCycleApi', method: 'post', data })
}

// 纠正措施管理查询
export function correctiveList(data) {
    return request({ url: '/corrective_action/QCorrectiveActionApi', method: 'post', data })
}

// 纠正措施管理新增
export function correctiveAdd(data) {
    return request({ url: '/corrective_action/ACorrectiveActionApi', method: 'post', data })
}

// 纠正措施管理编辑
export function correctiveEdit(data) {
    return request({ url: '/corrective_action/UCorrectiveActionApi', method: 'post', data })
}

// 纠正措施管理删除
export function correctiveDel(data) {
    return request({ url: '/corrective_action/DCorrectiveActionApi', method: 'post', data })
}
